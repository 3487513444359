import Immutable from 'immutable';

import actionTypes from 'constants/modals-action-types';
import {createReducer} from 'redux-store';

const initialState = () => {
    return {
        modals: Immutable.OrderedMap(),
    };
};

/**
 * The modals store maintains state for modals to be overlayed on top of normal
 * page content, in a separate DOM layer in the signed-in view. Modals will be
 * displayed in the order they were registered, i.e. the last modal registered
 * will be displayed on top of the previous one, and so on.
 */
const actionsMap = {
    [actionTypes.REGISTER_MODAL]: (state, action) => {
        return {
            modals: state.modals.set(action.modalId, action.modal),
        };
    },
    [actionTypes.RETIRE_MODAL]: (state, action) => {
        return {
            modals: state.modals.delete(action.modalId),
        };
    },
    [actionTypes.DISPOSE]: (state, action) => {
        return initialState();
    },
};

export default createReducer(initialState(), actionsMap);
