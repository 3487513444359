import PropTypes from 'prop-types';
import Immutable from 'immutable';
import {injectIntl, FormattedMessage} from 'react-intl';
import {routerActions} from 'actions/router-actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import isNil from 'lodash/isNil';
import noop from 'lodash/noop';

// API
import * as UsersApi from 'api/users-api';

// components
import formManager from 'components/forms/form-manager';
import FormInput from 'components/form-elements/form-input';

// constants
import ErrorCodes from 'constants/error-codes';
import globalMessages from 'intl/global-messages';
import messages from 'intl/anonymous-messages';

// utils
import * as ApiUtils from 'utils/api-utils';
import FormUtils from 'utils/form-utils';
import MeteorCookies from 'utils/meteor-cookies';
import * as PasswordUtils from 'utils/password-utils';

import {disposeSessionStore} from 'actions/session-actions';

import S from './invalid-password.less';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            disposeSessionStore,
            replace: routerActions.replace,
        },
        dispatch,
    );
}

const {instanceOf, func, object} = PropTypes;

// Used when a user's password expires or when an admin forces them to reset it.
// NOT used for user to reset their own password when they've forgotten it.
// @ts-expect-error TS(1270) FIXME: Decorator function return type 'FC<WithIntlProps<a... Remove this comment to see the full error message
@injectIntl
// @ts-expect-error TS(2554) FIXME: Expected 1-2 arguments, but got 0.
@formManager()
// @ts-expect-error TS(1270) FIXME: Decorator function return type 'ConnectedComponent... Remove this comment to see the full error message
@connect(
    (state) => ({
        // @ts-expect-error TS(2339) FIXME: Property 'session' does not exist on type 'Default... Remove this comment to see the full error message
        loggedInUser: state.session.user,
        // @ts-expect-error TS(2339) FIXME: Property 'session' does not exist on type 'Default... Remove this comment to see the full error message
        settings: state.session.settings,
    }),
    mapDispatchToProps,
    null,
    {forwardRef: true},
)
export default class InvalidPassword extends React.Component {
    static propTypes = {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
        loggedInUser: instanceOf(Immutable.Map),
        // @ts-expect-error TS(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
        settings: instanceOf(Immutable.Map),
        handleSubmit: func,
        intl: object,
        location: object.isRequired,
        disposeSessionStore: func,
        replace: func,
    };

    static defaultProps = {
        loggedInUser: Immutable.Map(),
        settings: Immutable.Map(),
        handleSubmit: noop,
        disposeSessionStore: noop,
    };

    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            password: null,
            isPasswordResetSuccess: false,
        };
    }

    UNSAFE_componentWillMount() {
        const {replace} = this.props;
        if (isNil(MeteorCookies.getInstance().get('token'))) {
            replace('/');
        }
    }

    componentWillUnmount() {
        this.props.disposeSessionStore();
    }

    _onSave(data) {
        this.props.handleSubmit(this._update.bind(this))();
    }

    async _update(data) {
        const command = data.delete('confirmPassword');

        let nextState = {};
        try {
            await UsersApi.updateProfilePassword(command);
            nextState = {
                isPasswordResetSuccess: true,
                error: null,
            };
        } catch (err) {
            nextState = {
                error: err.response.data,
            };
        }
        this.setState(nextState);
    }

    currentPasswordStatus() {
        return noop;
    }

    passwordSuccess = async () => {
        try {
            await UsersApi.logout();
            const cookies = MeteorCookies.getInstance();
            cookies.remove('token');
            cookies.remove('clientId');
            this.props.replace('/login');
        } catch (err) {
            ApiUtils.handleUnknownApiError(err);
        }
    };

    cancel() {
        // if we want to take the user to last request link, use the following.
        // it will be safer to take the user back to login screen so that a new session token is generated.
        // const {
        //     location,
        //     replace
        // } = this.props;
        // let nextPath = '/';
        // if (!isUndefined(location.state) && !isNull(location.state)) {
        //     let {nextPathName} = location.state;
        //     if (!isUndefined(nextPathName)) {
        //         nextPath = nextPathName;
        //     }
        // }
        // replace(nextPath);

        this.props.replace('/login');
    }

    passwordFailure() {
        return noop;
    }

    onNewPasswordChanged(data) {
        this.setState({
            newPassword: data,
        });
    }

    render() {
        const {loggedInUser, intl, settings} = this.props;
        const {newPassword, password, isPasswordResetSuccess, error} = this.state;
        const {formatMessage} = intl;
        const passwordChangeRequired = loggedInUser.get('passwordChangeRequired');
        const passwordStrength = settings.get('passwordStrength', 1);

        return (
            <div className={S.main}>
                {!isPasswordResetSuccess ? (
                    <form className={S.container} noValidate onSubmit={FormUtils.preventSubmit}>
                        <p className={S.helpText}>
                            {passwordChangeRequired ? (
                                <FormattedMessage {...messages.expiredResetTitle} />
                            ) : (
                                <FormattedMessage {...messages.expiredTitle} />
                            )}
                            <span>. </span>
                            <FormattedMessage {...messages.expiredHelpText} />
                        </p>
                        <div className={S.inner}>
                            {!isNil(error) && <PasswordError error={error} />}
                            <FormInput
                                label={formatMessage(globalMessages.oldPassword)}
                                helpText={formatMessage(globalMessages.oldPasswordHelp)}
                                name='password'
                                required={true}
                                autoFocus={true}
                                defaultValue=''
                                onChange={this.currentPasswordStatus.bind(this)}
                                type='password'
                            />
                            <FormInput
                                label={formatMessage(globalMessages.newPassword)}
                                helpText={formatMessage(PasswordUtils.getHelpTextMessage(passwordStrength))}
                                name='newPassword'
                                required={true}
                                defaultValue=''
                                type='password'
                                onChange={this.onNewPasswordChanged.bind(this)}
                                validator={PasswordUtils.validateNewPassword.bind(
                                    this,
                                    passwordStrength,
                                    password,
                                    newPassword,
                                )}
                            />
                            <FormInput
                                label={formatMessage(globalMessages.confirmPassword)}
                                helpText={formatMessage(globalMessages.confirmPasswordHelp)}
                                name='confirmPassword'
                                required={true}
                                defaultValue=''
                                type='password'
                                validator={PasswordUtils.validateConfirmPassword.bind(this, newPassword)}
                            />
                            <div className={S.controls}>
                                <button type='button' className={S.saveButton} onClick={this._onSave.bind(this)}>
                                    <div className={S.buttonContent}>
                                        <i className={S.buttonIcon}>save</i>
                                        <div>
                                            <FormattedMessage {...globalMessages.save} />
                                        </div>
                                    </div>
                                </button>
                                {!isNil(error) && (
                                    <button type='button' className={S.cancelButton} onClick={this.cancel.bind(this)}>
                                        <div className={S.buttonContent}>
                                            <i className={S.buttonIcon}>arrow_back</i>
                                            <div>
                                                <FormattedMessage {...messages.returnToLogin} />
                                            </div>
                                        </div>
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                ) : (
                    <div className={S.container}>
                        <div className={S.inner}>
                            <p className={S.resetSuccess}>
                                <FormattedMessage
                                    {...messages.resetSuccessDescriptionWithLink}
                                    values={{
                                        link: (
                                            <span onClick={this.passwordSuccess} className={S.returnToLoginLink}>
                                                <FormattedMessage {...messages.clickHere} />
                                            </span>
                                        ),
                                    }}
                                />
                            </p>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const PasswordError = ({error}) => {
    let errorMessageObj;

    if (error.errorCode === ErrorCodes.REPEAT_PASSWORD.errorCode) {
        errorMessageObj = globalMessages.oldMatch;
    } else if (error.errorCode === ErrorCodes.MISMATCH_PASSWORD.errorCode) {
        errorMessageObj = globalMessages.incorrect;
    } else {
        errorMessageObj = messages.resetPasswordFailed;
    }
    return (
        <p className={S.errorText}>
            <FormattedMessage {...errorMessageObj} />
        </p>
    );
};

PasswordError.propTypes = {
    error: object.isRequired,
};
