import Immutable from 'immutable';
import defaultTo from 'lodash/defaultTo';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import validator from 'validator';

import {SYSTEM_ADMIN, PARTICIPANT, ADMINISTRATOR} from 'constants/LU_UserGroupTypes';
import {
    COURSE_OWNER,
    COURSE_CONTRIBUTOR,
    COURSE_PARTICIPANT,
    COURSE_PATIENT,
    COURSE_MONITOR,
} from 'constants/LU_PermissionGroups';

import globalMessages from 'intl/global-messages';

import type {IntlShape} from 'react-intl';

type OptionalErrors = {
    errors?: object[] | string[];
};

export const getUserId = function (user) {
    return isNil(user) ? undefined : user.get('userId');
};

export const isValidEmailFormat = (email: string): boolean => {
    return !isEmpty(email) && validator.isEmail(email);
};

export const isValidUserNameFormat = (userName: string): boolean => {
    return (
        !isEmpty(userName) &&
        (validator.isEmail(userName) || (/^\S+$/.test(userName) && userName.length >= 3 && userName.length <= 64))
    );
};

export const validateUserName = function (userNameExists, currentUserName) {
    return (v) => {
        const m: OptionalErrors = {};
        let errors = [];

        if (!isUndefined(v) && !isNull(v)) {
            if (userNameExists) {
                if (!currentUserName || currentUserName.toLowerCase() !== v.toLowerCase()) {
                    errors.push(globalMessages.usernameErrorAlreadyExists);
                }
            }
            if (!isValidUserNameFormat(v)) {
                errors.push(globalMessages.usernameErrorFormat);
            }
        } else {
            errors = [globalMessages.usernameErrorPromptValid];
        }
        m.errors = errors;
        return m;
    };
};

/**
 * To avoid 400 errors when validating asynchronously on the server, don't try
 * to send the new name to see if it exists unless it's a valid username.
 */
export const prevalidateUserName = function (v) {
    const m: OptionalErrors = {};
    const errors = [];

    if (!isUndefined(v)) {
        if (!isValidUserNameFormat(v)) {
            errors.push(globalMessages.userNameError);
        }
    }
    m.errors = errors;
    return m;
};

export const preValidateEmail = function (v) {
    const errors = [];

    if (!isNil(v) && !validator.isEmail(v)) {
        errors.push(globalMessages.emailErrorFormat);
    }
    return {errors};
};

/**
 * Users that have at least one organization in common with the logged in
 * user are visible to the logged in user. Otherwise we can display their
 * names here but can't link to them because we'll get a 404. Sysadmins can
 * see everyone regardless of org.
 */
export const userIsVisible = function (loggedInUser, other) {
    if (!loggedInUser || loggedInUser.isEmpty() || !other || other.isEmpty()) {
        return false;
    }

    if (!loggedInUser.get('locked') && other.get('locked')) {
        return false;
    }

    // for some reason the logged in user has userGroups as a direct child
    // prop instead of an indirect descendant through roles
    if (loggedInUser.get('userGroups') && loggedInUser.getIn(['userGroups', 0])) {
        if (loggedInUser.getIn(['userGroups', 0, 'name']) === 'System Admin') {
            return true;
        }
    }

    if (!loggedInUser.get('organizations') || !other.get('organizations')) {
        return false;
    }

    return loggedInUser.get('organizations').some((userOrg) => {
        return other.get('organizations').some((otherOrg) => {
            return userOrg.get('organizationId') === otherOrg.get('organizationId');
        });
    });
};

export const userUrl = function (user) {
    if (!user || !user.get('userId')) {
        return undefined;
    }

    return `/users/${user.get('userId')}`;
};

/*
 * when clicking on a link that should theoretically go to a user detail page,
 * either direct the user to profile page instead if the user is not a system
 * administrator.
 */
export const shouldGoToProfile = (loggedInUser, targetUserId) => {
    const userGroupTypeRef = loggedInUser.getIn(['userGroups', 0, 'userGroupType', 'ref']);

    return targetUserId === loggedInUser.get('userId') && userGroupTypeRef !== SYSTEM_ADMIN;
};

export const isSupportAdmin = (loggedInUser) => {
    return loggedInUser?.get('locked');
};

/*
 *  Hopefully, a temporary util used in SessionViewer for the sake of checking if a user
 *  is a system admin, which determines whether they get the evaluation tab or the reflection tab
 */
export const isSystemAdmin = (loggedInUser) => {
    const userGroupTypeRef = loggedInUser.getIn(['userGroups', 0, 'userGroupType', 'ref']);
    return userGroupTypeRef === SYSTEM_ADMIN;
};

export const isParticipant = (user) => {
    const userGroupTypeRef = user.getIn(['userGroups', 0, 'userGroupType', 'ref']);
    return userGroupTypeRef === PARTICIPANT;
};

export const isAdmin = (user) => {
    const userGroupTypeRef = user.getIn(['userGroups', 0, 'userGroupType', 'ref']);
    return userGroupTypeRef === ADMINISTRATOR;
};

/**
 * @param {Immutable.Map} user
 * @return {string}
 */
export const getLastNameFirst = (user) => {
    return isNil(user.get('middleName'))
        ? `${user.get('lastName')}, ${user.get('firstName')}`
        : `${user.get('lastName')}, ${user.get('firstName')} ${user.get('middleName')}`;
};

/**
 * @param {object} user
 * @return {string}
 */
export const getLastNameFirstClobberedJS = (user) => {
    return getLastNameFirstFromParts(user.firstName, user.middleName, user.lastName);
};

export const getFirstNameFirstFromParts = (first, middle, last) => {
    return isNil(middle) ? `${first} ${last}` : `${first} ${middle} ${last}`;
};

/**
 * @param {string} first
 * @param {string} middle
 * @param {string} last
 * @return {string}
 */
export const getLastNameFirstFromParts = (first, middle, last) => {
    return isNil(middle) ? `${last}, ${first}` : `${last}, ${first} ${middle}`;
};

export const getLastNameFirstShort = (user) => {
    return `${user.get('lastName')}, ${user.get('firstName')}`;
};

export const getNameFromOrgOrUser = (item) => {
    return item.has('userId') ? getLastNameFirst(item) : item.get('name');
};

export const orgOrUserIsUser = (item) => {
    return item.has('userId');
};

/**
 * Util that returns a subset of sorted users filtered on a defined userSearchValue from all availableUsers.
 * The returned users are sorted from firstName => middleName => lastName.
 *
 * @param {*} sortedUsers
 * @param {string} userSearchValue
 * @returns {*} sortedSearchedUsers
 */
export const getSortedSearchedUsers = (sortedUsers, userSearchValue) => {
    return sortedUsers.filter(
        (user) =>
            user.get('firstName').toLowerCase().includes(userSearchValue) ||
            defaultTo(user.get('middleName'), '').toLowerCase().includes(userSearchValue) ||
            user.get('lastName').toLowerCase().includes(userSearchValue),
    );
};

/**
 * Util that returns sorted users.
 *
 * @param {*} users
 * @returns {*} sortedUsers
 */
export const getSortedUsers = (users) => {
    return users.sortBy(getLastNameFirst, (a, b) => a.localeCompare(b));
};

/**
 * @param {Immutable.Map} user1
 * @param {Immutable.Map} user2
 * @param {string | string[]} [locales]
 * @returns {number}
 */
export const compareUsers = (user1, user2, locales = 'en-US') => {
    let val = 0;
    if (isNil(user2)) {
        val = 1;
    } else if (isNil(user1)) {
        val = -1;
    } else if (user1.get('userId') === user2.get('userId')) {
        val = 0;
    } else {
        const name1 = getLastNameFirst(user1).toLowerCase();
        const name2 = getLastNameFirst(user2).toLowerCase();
        val = name1.localeCompare(name2, locales);
    }
    return val;
};

/**
 * @param {string | string[]} [locales]
 * @returns {function(Immutable.Map, Immutable.Map): number}
 */
export const lastNameFirstComparator =
    (locales = 'en-US') =>
    (user1, user2) => {
        return compareUsers(user1, user2, locales);
    };

export const compareOrganizationsAndUsers = (item1, item2) => {
    if (isNil(item2)) {
        return 1;
    }

    if (isNil(item1)) {
        return -1;
    }

    const label1 = getNameFromOrgOrUser(item1).toLowerCase();
    const label2 = getNameFromOrgOrUser(item2).toLowerCase();
    return label1.localeCompare(label2);
};

/**
 * Given a list of courses, formats course roles into the proper string
 * Sort order for course roles: Owner, Contributor, Participant, Standard Patient, Monitor
 *
 * @returns list of courses with correctly formatted course roles
 */
export const formatCourseRoles = (
    courses: Immutable.List<Immutable.Map<unknown, unknown>>,
    intl: IntlShape,
): Immutable.List<Immutable.Map<unknown, unknown>> => {
    const {formatMessage} = intl;

    const refToStringEnum = {
        [COURSE_OWNER]: globalMessages.owner,
        [COURSE_CONTRIBUTOR]: globalMessages.contributor,
        [COURSE_PARTICIPANT]: globalMessages.participant,
        [COURSE_PATIENT]: globalMessages.standardPatient,
        [COURSE_MONITOR]: globalMessages.monitor,
    };

    let formattedCourseRoles = Immutable.List();

    courses.forEach((course) => {
        let courseRoles = course.get('courseRefs');
        // if more than one role, sort in this order: Owner/Contributor/Participant/SP/Monitor
        // @ts-expect-error TS(2339) FIXME: Property 'size' does not exist on type 'unknown'.
        if (courseRoles.size > 1) {
            const orderedCourseRoles = [
                COURSE_OWNER,
                COURSE_CONTRIBUTOR,
                COURSE_PARTICIPANT,
                COURSE_PATIENT,
                COURSE_MONITOR,
            ];
            // @ts-expect-error TS(2339) FIXME: Property 'sort' does not exist on type 'unknown'.
            courseRoles = courseRoles.sort((a, b) => {
                return orderedCourseRoles.indexOf(a) - orderedCourseRoles.indexOf(b);
            });
        }
        courseRoles = courseRoles
            // @ts-expect-error TS(2339) FIXME: Property 'map' does not exist on type 'unknown'.
            .map((role) => {
                return formatMessage(refToStringEnum[role]);
            })
            .join(', ');
        const formattedCourse = course.set('formattedCourseRoles', courseRoles);
        formattedCourseRoles = formattedCourseRoles.push(formattedCourse);
    });

    // @ts-expect-error TS(2322) FIXME: Type 'List<unknown>' is not assignable to type 'Li... Remove this comment to see the full error message
    return formattedCourseRoles;
};

/**
 * Sort and format full names with the '|' partition between each.
 *
 * @param {*} users
 * @param {string} partitionCharacter
 * @returns {string} sortedPartitionedUsers
 */
export const sortAndFormatFullNamesWithPartition = (users, partitionCharacter: string): string => {
    const sortedPartitionedUsers = users.sortBy((u) =>
        getLastNameFirstFromParts(u.get('userFirstName'), u.get('userMiddleName'), u.get('userLastName')),
    );
    return sortedPartitionedUsers
        .map((u) => getLastNameFirstFromParts(u.get('userFirstName'), u.get('userMiddleName'), u.get('userLastName')))
        .join(partitionCharacter);
};

export const getCanSwitchClient = (loggedInUser) => {
    return (
        loggedInUser.get('cluster') &&
        (isSystemAdmin(loggedInUser) || loggedInUser.get('clients', Immutable.List()).size > 1)
    );
};
