import Immutable from 'immutable';

export const RESTORE_STORE = 'RESTORE_STORE';

export const AUTHENTICATE_SESSION = 'AUTHENTICATE_SESSION';
export const REGISTER_SESSION_USER = 'REGISTER_SESSION_USER';
export const DISMISS_STATUS_WARNING = 'DISMISS_STATUS_WARNING';

export const UPDATE_NEXT_LOCATION = 'UPDATE_NEXT_LOCATION';
export const DISPOSE_SESSION_STORE = 'DISPOSE_SESSION_STORE';
export const DISPOSE_PASSWORD_STORE = 'DISPOSE_PASSWORD_STORE';

export const SESSION_IDLING_INIT = 'SESSION_IDLING_INIT';
export const SESSION_IDLING_DEACTIVATE = 'SESSION_IDLING_DEACTIVATE';
export const SESSION_IDLING_RESET = 'SESSION_IDLING_RESET';
export const SESSION_IDLING_WARN = 'SESSION_IDLING_WARN';
// these actions will reset the idling time
export const SESSION_IDLING_EVENTS = Immutable.fromJS(['mousemove', 'keydown', 'click', 'resize', 'touchstart']);

export const LOOKUPS_REQUEST = 'LOOKUPS_REQUEST';
export const LOOKUPS_SUCCESS = 'LOOKUPS_SUCCESS';

export const SEND_NOTIFICATIONS_REQUEST = 'SESSION_SEND_NOTIFICATIONS_REQUEST';
export const SEND_NOTIFICATIONS_SUCCESS = 'SESSION_SEND_NOTIFICATIONS_SUCCESS';

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';

export const USER_PERMISSIONS_REQUEST = 'USER_PERMISSIONS_REQUEST';
export const USER_PERMISSIONS_SUCCESS = 'USER_PERMISSIONS_SUCCESS';

export const AUTHENTICATE_USER_REQUEST = 'AUTHENTICATE_USER_REQUEST';
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
export const AUTHENTICATE_USER_FAILURE = 'AUTHENTICATE_USER_FAILURE';

export const GET_CLIENT_SSO_LOGIN_URL_REQUEST = 'GET_CLIENT_SSO_LOGIN_URL_REQUEST';
export const GET_CLIENT_SSO_LOGIN_URL_SUCCESS = 'GET_CLIENT_SSO_LOGIN_URL_SUCCESS';
export const GET_CLIENT_SSO_LOGIN_URL_FAILURE = 'GET_CLIENT_SSO_LOGIN_URL_FAILURE';

export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';

export const DISPOSE_STORES_FOR_MULTIPLE_USERS = 'DISPOSE_STORES_FOR_MULTIPLE_USERS';

export const SETTINGS_REQUEST = 'SETTINGS_REQUEST';
export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS';

export const SETTINGS_UPDATE_REQUEST = 'SETTINGS_UPDATE_REQUEST';
export const SETTINGS_UPDATE_SUCCESS = 'SETTINGS_UPDATE_SUCCESS';

export const PROFILE_CONTACT_EDIT_REQUEST = 'PROFILE_CONTACT_EDIT_REQUEST';
export const PROFILE_CONTACT_EDIT_SUCCESS = 'PROFILE_CONTACT_EDIT_SUCCESS';

export const PROFILE_PASSWORD_EDIT_REQUEST = 'PROFILE_PASSWORD_EDIT_REQUEST';
export const PROFILE_PASSWORD_EDIT_SUCCESS = 'PROFILE_PASSWORD_EDIT_SUCCESS';
export const PROFILE_PASSWORD_EDIT_FAILURE = 'PROFILE_PASSWORD_EDIT_FAILURE';

export const PROFILE_UPLOAD_AVATAR_REQUEST = 'PROFILE_UPLOAD_AVATAR_REQUEST';
export const PROFILE_UPLOAD_AVATAR_SUCCESS = 'PROFILE_UPLOAD_AVATAR_SUCCESS';

export const PROFILE_CHECK_USERNAME_REQUEST = 'PROFILE_CHECK_USERNAME_REQUEST';
export const PROFILE_CHECK_USERNAME_SUCCESS = 'PROFILE_CHECK_USERNAME_SUCCESS';

export const PROFILE_CHECK_EMAIL_REQUEST = 'PROFILE_CHECK_EMAIL_REQUEST';
export const PROFILE_CHECK_EMAIL_SUCCESS = 'PROFILE_CHECK_EMAIL_SUCCESS';

export const FETCH_USER_PREFERENCES_REQUEST = 'FETCH_USER_PREFERENCES_REQUEST';
export const FETCH_USER_PREFERENCES_SUCCESS = 'FETCH_USER_PREFERENCES_SUCCESS';

export const UPDATE_USER_PREFERENCES_REQUEST = 'UPDATE_USER_PREFERENCES_REQUEST';
export const UPDATE_USER_PREFERENCES_SUCCESS = 'UPDATE_USER_PREFERENCES_SUCCESS';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const REQUEST_RESET_PASSWORD_REQUEST = 'REQUEST_RESET_PASSWORD_REQUEST';
export const REQUEST_RESET_PASSWORD_SUCCESS = 'REQUEST_RESET_PASSWORD_SUCCESS';

export const UNAUTHORIZED_REQUEST = 'UNAUTHORIZED_REQUEST';
export const DISMISS_UNAUTHORIZED = 'DISMISS_UNAUTHORIZED';
export const DISMISS_FROM_RECORDING_START = 'DISMISS_FROM_RECORDING_START';

export const WifiIndicatorSignalStrength = Object.freeze({
    Excellent: 3,
    Good: 2,
    Low: 1,
    Poor: 0,
});
export const WifiIndicatorSignalStrengthLimits = Object.freeze({
    [WifiIndicatorSignalStrength.Excellent]: {low: 9, high: 10},
    [WifiIndicatorSignalStrength.Good]: {low: 8, high: 8},
    [WifiIndicatorSignalStrength.Low]: {low: 7, high: 7},
    [WifiIndicatorSignalStrength.Poor]: {low: 0, high: 6},
});
