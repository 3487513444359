import {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {bindActionCreators} from 'redux';

import * as SessionActions from 'actions/session-actions';

// components
import LoginProgress from 'components/login-progress';

// constants
import anonymousMessages from 'intl/anonymous-messages';

type FailureCallback = (err: unknown) => void;
type SuccessCallback = () => void;

type ReduxDispatchProps = {
    fetchUserPermissions: (successCallback: SuccessCallback, failureCallback: FailureCallback) => void;
    fetchUserPreferences: (successCallback: SuccessCallback, failureCallback: FailureCallback) => void;
    fetchUserProfile: (successCallback: SuccessCallback, failureCallback: FailureCallback) => void;
};

type ImpersonateUserSuccessProps = ReduxDispatchProps;

/**
 * The user arrives here after successfully logging in via impersonation. The data of the impersonated user must be
 * loaded in a separate page that the impersonated user has access to, to avoid triggering rendering errors during the
 * process of loading all the necessary information before rendering their dashboard.
 */
const ImpersonateUserSuccess = (props: ImpersonateUserSuccessProps) => {
    const {fetchUserPermissions, fetchUserPreferences, fetchUserProfile} = props;
    const history = useHistory();
    const intl = useIntl();
    const {formatMessage} = intl;

    const onImpersonateUserSuccess = async () => {
        const failureFn = (err) => {
            console.error(err);
        };

        fetchUserPermissions(() => {
            fetchUserProfile(() => {
                fetchUserPreferences(() => {
                    history.replace('/');
                }, failureFn);
            }, failureFn);
        }, failureFn);
    };

    // executes once upon initial load
    useEffect(() => {
        onImpersonateUserSuccess();
    }, []);

    return <LoginProgress messageText={formatMessage(anonymousMessages.authenticating)} />;
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchUserPermissions: SessionActions.fetchUserPermissions,
            fetchUserPreferences: SessionActions.fetchUserPreferences,
            fetchUserProfile: SessionActions.fetchUserProfile,
        },
        dispatch,
    );
};

export default connect<never, ReduxDispatchProps>(null, mapDispatchToProps)(ImpersonateUserSuccess);
