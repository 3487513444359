import PropTypes from 'prop-types';
import Immutable from 'immutable';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {routerActions} from 'actions/router-actions';
import {injectIntl, FormattedMessage} from 'react-intl';

import isNil from 'lodash/isNil';

import {resetPassword} from 'actions/session-actions';
import ErrorCodes from 'constants/error-codes';
import {setStateSafe} from 'utils/component-utils';
import FormUtils from 'utils/form-utils';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';

import SCButton from 'components/sc-button';
import formManager from 'components/forms/form-manager';
import * as PasswordUtils from 'utils/password-utils';
import FormInput from 'components/form-elements/form-input';

import globalMessages from 'intl/global-messages';
import messages from 'intl/anonymous-messages';

import S from './reset-password.less';
import {errorCodesAreEqual} from 'utils/ui-error-code-utils';

const {any, func, instanceOf} = PropTypes;

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetPassword,
            ...routerActions,
        },
        dispatch,
    );
}

// @ts-expect-error TS(1270) FIXME: Decorator function return type 'FC<WithIntlProps<a... Remove this comment to see the full error message
@injectIntl
// @ts-expect-error TS(2554) FIXME: Expected 1-2 arguments, but got 0.
@formManager()
// @ts-expect-error TS(1270) FIXME: Decorator function return type 'ConnectedComponent... Remove this comment to see the full error message
@connect(
    (state) => ({
        // @ts-expect-error TS(2339) FIXME: Property 'session' does not exist on type 'Default... Remove this comment to see the full error message
        client: state.session.client,
        // @ts-expect-error TS(2339) FIXME: Property 'session' does not exist on type 'Default... Remove this comment to see the full error message
        settings: state.session.settings,
    }),
    mapDispatchToProps,
    null,
    {forwardRef: true},
)
export default class ResetPassword extends React.Component {
    static propTypes = {
        resetPassword: func,
        // @ts-expect-error TS(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
        settings: instanceOf(Immutable.Map),
        handleSubmit: func,
        replace: func,
        push: func,
        intl: any,
        client: any,
    };

    static defaultProps = {};

    constructor(props, context) {
        super(props, context);

        this.state = {
            newPassword: '',
            password: null,
        };
        this.setStateSafe = setStateSafe.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleResetPassword(data) {
        this.setState({
            inProgress: true,
        });
        this.props.resetPassword(data.get('newPassword'), this.onResetPasswordSuccess, this.onResetPasswordFailure);
    }

    onResetPasswordSuccess = () => {
        this.setStateSafe({
            passwordResetSuccessful: true,
            inProgress: false,
        });
    };

    onResetPasswordFailure = (errorResponse) => {
        let errorMessage = messages.resetError;
        let passwordStrength = 1;

        const errorCode = errorResponse;
        const additionalData = errorResponse.get('additionalData');

        if (errorCodesAreEqual(errorCode, ErrorCodes.BAD_PASSWORD) && !isNil(additionalData)) {
            // this is an error state where the new password doesn't meet the strength requirements
            errorMessage = null;
            passwordStrength = additionalData;
        }

        this.setStateSafe({
            passwordResetSuccessful: false,
            passwordStrength,
            errorMessage,
            inProgress: false,
        });
    };

    onNewPasswordChanged(data) {
        this.setState({
            newPassword: data,
        });
    }

    returnToLogin() {
        this.props.push('/');
    }

    render() {
        const {intl, handleSubmit, settings} = this.props;
        const {errorMessage, newPassword, password, passwordResetSuccessful, inProgress} = this.state;

        const {formatMessage} = intl;

        const onSubmit = handleSubmit(this.handleResetPassword.bind(this));

        // MET-6155 This will return the wrong thing until the user submits a bad request
        const passwordStrength = isNil(this.state.passwordStrength)
            ? settings.get('passwordStrength', 1)
            : this.state.passwordStrength;
        const passwordHelpText = formatMessage(PasswordUtils.getHelpTextMessage(passwordStrength));

        return (
            <div className={S.main}>
                <form className={S.form} noValidate onSubmit={FormUtils.preventSubmit}>
                    {passwordResetSuccessful ? (
                        <div className={S.passwordResetSuccessful}>
                            <div className={S.resetDescription}>
                                <label className={S.label}>
                                    <FormattedMessage {...messages.resetSuccessTitle} />
                                </label>
                                <p>
                                    <FormattedMessage {...messages.resetSuccessDescription} />
                                </p>
                            </div>
                            <SCButton
                                variant='secondary'
                                disabled={false}
                                inProgress={false}
                                onClick={this.returnToLogin.bind(this)}
                                startIcon={<ArrowBackIcon />}
                                label={formatMessage(globalMessages.back)}
                            />
                        </div>
                    ) : (
                        <div>
                            <div className={S.resetDescription}>
                                <label className={S.label}>
                                    <FormattedMessage {...messages.resetPasswordTitle} />
                                </label>
                                <p>
                                    <FormattedMessage {...messages.resetPasswordDescription} />
                                </p>
                            </div>
                            <div className={S.inner}>
                                <FormInput
                                    label={formatMessage(globalMessages.newPassword)}
                                    helpText={passwordHelpText}
                                    name='newPassword'
                                    required={true}
                                    defaultValue=''
                                    type='password'
                                    onChange={this.onNewPasswordChanged.bind(this)}
                                    validator={PasswordUtils.validateNewPassword.bind(
                                        this,
                                        passwordStrength,
                                        password,
                                        newPassword,
                                    )}
                                />
                                <FormInput
                                    label={formatMessage(globalMessages.confirmPassword)}
                                    helpText={formatMessage(globalMessages.confirmPasswordHelp)}
                                    name='confirmPassword'
                                    required={true}
                                    defaultValue=''
                                    type='password'
                                    validator={PasswordUtils.validateConfirmPassword.bind(this, newPassword)}
                                />
                                {isNil(errorMessage) ? null : (
                                    <div className={S.errorMessage}>
                                        <p className={S.errorText}>
                                            <FormattedMessage {...errorMessage} />
                                        </p>
                                    </div>
                                )}
                                <div className={S.controlsContainer}>
                                    <SCButton
                                        variant='secondary'
                                        disabled={false}
                                        inProgress={false}
                                        onClick={this.returnToLogin.bind(this)}
                                        startIcon={<ArrowBackIcon />}
                                        label={formatMessage(globalMessages.back)}
                                    />
                                    <SCButton
                                        variant='primary'
                                        disabled={false}
                                        inProgress={inProgress}
                                        onClick={onSubmit}
                                        startIcon={<SaveIcon />}
                                        label={formatMessage(globalMessages.save)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </form>
            </div>
        );
    }
}
