import Immutable from 'immutable';

export const createRequestAction = (action, config) => {
    const nextAction = Object.assign({}, action, {
        type: config.types[0],
        data: config.data,
    });
    return deleteObjectSymbols(nextAction);
};

export const createSuccessAction = (action, config, response) => {
    const nextAction = Object.assign({}, action, {
        type: config.types[1],
        data: response.data,
    });
    return deleteObjectSymbols(nextAction);
};

export const enforcePlainJSData = (data) => {
    if (Immutable.isImmutable(data)) {
        data = data.toJS();
    }
    return data;
};

const deleteObjectSymbols = (obj) => {
    Object.getOwnPropertySymbols(obj).forEach((symbol) => {
        delete obj[symbol];
    });

    return obj;
};
