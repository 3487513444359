import PropTypes from 'prop-types';
import React, {useContext} from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import {makeStyles} from '@material-ui/core/styles';

import * as ModalConstants from 'components/modals/modal-constants';
import {ModalContext} from 'components/modals/modal-context';

const useStyles = makeStyles((theme) => {
    const dialogContentRoot = {
        ...theme.typography.componentS,
    };

    return {
        rootSmall: {
            ...dialogContentRoot,
            paddingLeft: ModalConstants.modalMarginSm,
            paddingRight: ModalConstants.modalMarginSm,
        },
        rootMedium: {
            ...dialogContentRoot,
            paddingLeft: ModalConstants.modalMarginMd,
            paddingRight: ModalConstants.modalMarginMd,
        },
        rootLarge: {
            ...dialogContentRoot,
            paddingLeft: ModalConstants.modalMarginLg,
            paddingRight: ModalConstants.modalMarginLg,
        },
    };
});

const ModalContent = ({children}) => {
    const classes = useStyles();
    const {size} = useContext(ModalContext);

    let root;
    switch (size) {
        case 'small':
            root = classes.rootSmall;
            break;
        case 'large':
            root = classes.rootLarge;
            break;
        case 'medium':
        default:
            root = classes.rootMedium;
            break;
    }

    return <DialogContent classes={{root}}>{children}</DialogContent>;
};

ModalContent.propTypes = {
    children: PropTypes.any.isRequired,
};

export default ModalContent;
