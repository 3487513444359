import Immutable from 'immutable';

import isNil from 'lodash/isNil';

const getInstanceRole = (instance, permissionGroupName) => {
    const roles = instance.get('roles', Immutable.List());

    return roles.find(
        (role) => {
            return role.getIn(['permissionGroup', 'ref']) === permissionGroupName;
        },
        null,
        Immutable.Map(),
    );
};

export const getOwnerFromInstance = (instance, ownerPermissionGroup) => {
    if (isNil(instance) || isNil(ownerPermissionGroup)) {
        return Immutable.Map();
    }

    const instanceOwnerRole = getInstanceRole(instance, ownerPermissionGroup);

    return instanceOwnerRole.getIn(['users', 0], Immutable.Map());
};

export const getUserGroupUsersFromInstanceRoles = (instance, permissionGroupName) => {
    if (isNil(instance) || isNil(permissionGroupName)) {
        return Immutable.List();
    }

    const instanceUserRole = getInstanceRole(instance, permissionGroupName);

    const instanceUserGroup = instanceUserRole.get('userGroups', Immutable.List()).find(
        (userGroup) => {
            return userGroup.get('userGroupTypeId') === instanceUserRole.getIn(['permissionGroup', 'userGroupTypeId']);
        },
        null,
        Immutable.Map(),
    );

    return instanceUserGroup.get('users', Immutable.List());
};

export const getOrgsFromInstanceRoles = (instance, permissionGroupName) => {
    if (isNil(instance) || isNil(permissionGroupName)) {
        return Immutable.List();
    }
    const instanceUserRole = getInstanceRole(instance, permissionGroupName);

    return instanceUserRole.get('organizations', Immutable.List());
};
