import Immutable from 'immutable';
import * as actionTypes from 'constants/app-action-types';
import {createReducer} from 'redux-store';

const initialState = () => {
    return {
        evaluationTimeRefToIdMap: Immutable.Map(),
        evaluationTimeIdToRefMap: Immutable.Map(),
        lookupMaps: Immutable.Map(),
    };
};

/**
 * The app store maintains global state for the application. This is any state
 * that might exist regardless of whether a user is signed in or not, above the
 * level of individual routes/views.
 */
const actionsMap = {
    [actionTypes.GET_LOOKUP_EVALUATION_TIME_SUCCESS]: (state, action) => {
        const evaluationTimeIdToRefMap = {};
        const evaluationTimeRefToIdMap = {};
        action.data.forEach((evaluationTime) => {
            evaluationTimeRefToIdMap[evaluationTime.get('ref')] = evaluationTime.get('evaluationTimeId');
            evaluationTimeIdToRefMap[evaluationTime.get('evaluationTimeId')] = evaluationTime.get('ref');
        });
        return {
            evaluationTimeRefToIdMap: Immutable.fromJS(evaluationTimeRefToIdMap),
            evaluationTimeIdToRefMap: Immutable.fromJS(evaluationTimeIdToRefMap),
        };
    },
    [actionTypes.SET_COMET_LOOKUPS]: (state, action) => {
        const lookupMaps = {};
        action.lookupMap.forEach((lookupList, lookupKey) => {
            const lookupIdToRefMap = {};
            const lookupRefToIdMap = {};
            lookupList.forEach((lookup) => {
                lookupRefToIdMap[lookup.get('ref')] = lookup.get(`${lookupKey}Id`);
                lookupIdToRefMap[lookup.get(`${lookupKey}Id`)] = lookup.get('ref');
            });
            lookupMaps[lookupKey] = {
                idToRefMap: lookupIdToRefMap,
                refToIdMap: lookupRefToIdMap,
            };
        });
        return {
            lookupMaps: Immutable.fromJS(lookupMaps),
        };
    },
    [actionTypes.DISPOSE]: (state, action) => {
        return initialState();
    },
};

export default createReducer(initialState(), actionsMap);
