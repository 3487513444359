import Immutable from 'immutable';

import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import isString from 'lodash/isString';

import * as UserUtils from 'utils/user-utils';

import type {List} from 'immutable';

export default class FormUtils {
    /*
     * Comparison function to match search string to a list of options objects.
     * Assumes that each option object has a [MODEL_NAME]Id and a name.
     */
    static objectCompareFunction = (name, o, n) => {
        const idName = `${name}Id`;
        if (isString(o)) {
            return n.get('name') === o;
        }
        if (isString(n)) {
            return o.get('name') === n;
        }
        if (o && n) {
            return o.get(idName) === n.get(idName);
        }
        return false;
    };

    static objectCompareFunctionWithNoneSelection = (name, option, selection) => {
        // Compares a "selection" against each "option" from the list of options
        let result = false;

        const idName = `${name}Id`;
        if (isString(option)) {
            result = selection.get('name') === option;
        }
        if (isString(selection)) {
            result = option.get('name') === selection;
        }
        if (Immutable.Map.isMap(option) && Immutable.Map.isMap(selection)) {
            if (selection.isEmpty()) {
                result = option.isEmpty();
            } else {
                result = option.get(idName) === selection.get(idName);
            }
        }

        return result;
    };

    static compareUsers = function (a, b) {
        if (isNil(a) || isNil(b)) {
            return false;
        }
        if (isString(a)) {
            return UserUtils.getLastNameFirst(b) === a;
        }
        if (isString(b)) {
            return UserUtils.getLastNameFirst(a) === b;
        }
        if (a.has('userId') && b.has('userId')) {
            return a.get('userId') === b.get('userId');
        }
        return false;
    };

    static compareWithUsersAndOrganizations = (o, n) => {
        if (isNil(o) || isNil(n)) {
            return false;
        }
        if (isString(o) && n.has('userId')) {
            return UserUtils.getLastNameFirstShort(n) === o;
        }
        if (isString(n) && o.has('userId')) {
            return UserUtils.getLastNameFirstShort(o) === n;
        }

        if (isString(o) && n.has('organizationId')) {
            return n.get('name') === o;
        }
        if (isString(n) && o.has('organizationId')) {
            return o.get('name') === n;
        }

        if (o.has('organizationId') && n.has('organizationId')) {
            return o.get('organizationId') === n.get('organizationId');
        }

        if (o.has('userId') && n.has('userId')) {
            return o.get('userId') === n.get('userId');
        }

        if (isString(o) && isString(n)) {
            return n === o;
        }

        return false;
    };

    /*
     * Comparison function to match search string to a list of options strings.
     */
    static stringCompareFunction = (o, n) => {
        return o === n;
    };

    /*
     * UI output for form options that returns the name from the option object.
     */
    static formGetString = (o, nullOption = '') => {
        let result = nullOption;

        if (!isNull(o)) {
            result = o.get('name');
        }

        return result;
    };

    static formGetStringWithNull = (nullOption = 'None', option) => {
        let result = nullOption;

        if (!isNil(option) && !option.isEmpty()) {
            result = option.get('name');
        }
        return result;
    };

    static formGetStringWithNullFromTitle = (nullOption = 'None', option) => {
        let result = nullOption;

        if (!isNil(option) && !option.isEmpty()) {
            result = option.get('title');
        }
        return result;
    };

    static addNoneSelectionToOptions = (options) => {
        return options.insert(0, Immutable.fromJS({}));
    };

    /*
     * Confirm that the selection made by the user is an object that exists from available options.
     * Returns an empty object or an object with a property "errors" that contains a list of errors.
     */
    static validateModelSelection = (options, name, errorMessage = null, enabled = true, selection) => {
        let valid = true;
        const m: {errors?: string[]} = {};
        const modelIdName = `${name}Id`;

        if (!enabled) {
            return m;
        }

        if (isNull(errorMessage) || !isString(errorMessage)) {
            errorMessage = 'Please make a valid selection.';
        }

        if (isNil(selection)) {
            valid = false;
        } else if (Immutable.List.isList(options)) {
            valid = options.some((option) => {
                if (isString(selection)) {
                    return FormUtils.formGetString(option) === selection;
                }
                return option.get(modelIdName) === selection.get(modelIdName);
            });
        }

        if (!valid) {
            m.errors = [errorMessage];
        }
        return m;
    };

    /*
     * This is a temporary solution. Should refactor in: MET-3856
     * All it does is return valid if the selection is null or undefined (because it's not required).
     * Otherwise, it validates using validateModelSelection which verifies that the selection is one
     * of the options.
     */
    static validateModelSelectionNotRequired = (options, name, errorMessage, enabled, selection) => {
        if (isNil(selection)) {
            return {}; // empty object means it's valid and there are no errors
        }
        return FormUtils.validateModelSelection(options, name, errorMessage, enabled, selection);
    };

    static validateModelSelectionOneAtLeast = (options, name, errorMessage = null, enabled = true, selection) => {
        let valid = true;
        const m: {errors?: string[]} = {};
        const modelIdName = `${name}Id`;

        if (!enabled) {
            return m;
        }

        if (isNull(errorMessage) || !isString(errorMessage)) {
            errorMessage = 'Please make a valid selection.';
        }

        if (isString(selection)) {
            return m;
        }

        if (selection.isEmpty()) {
            valid = false;
        } else if (Immutable.List.isList(options)) {
            const isValidSelection = selection.forEach((value) => {
                return options.some((option) => {
                    if (isString(value)) {
                        return FormUtils.formGetString(option) === value;
                    }
                    return option.get(modelIdName) === value.get(modelIdName);
                });
            });
            if (!isValidSelection) {
                valid = false;
            }
        }

        if (!valid) {
            m.errors = [errorMessage];
        }

        return m;
    };

    // todo: localize default error message
    static validateUser = (options, enabled, errorMessage) => {
        return (selection) => {
            if (isNil(errorMessage) || !isString(errorMessage)) {
                errorMessage = 'Please make a valid selection.';
            }

            let result: {errors?: string[]} = {
                errors: [errorMessage],
            };

            if (
                !enabled ||
                (!isNil(selection) &&
                    Immutable.List.isList(options) &&
                    options.some((option) => {
                        return FormUtils.compareUsers(option, selection);
                    }))
            ) {
                result = {};
            }

            return result;
        };
    };

    // todo: localize default error message
    static validateBulkObjectSelection = (modelName, options, enabled, errorMessage) => {
        return (selection) => {
            if (isNil(errorMessage) || !isString(errorMessage)) {
                errorMessage = 'Please make a valid selection.';
            }

            let result: {errors?: string[]} = {
                errors: [errorMessage],
            };

            if (
                !enabled ||
                (!isNil(selection) &&
                    Immutable.List.isList(options) &&
                    options.some((option) => {
                        return FormUtils.objectCompareFunction(modelName, option, selection);
                    }))
            ) {
                result = {};
            }

            return result;
        };
    };

    // assumes that if the selection is any kind of object, it's valid
    // todo: localize default error message
    static validateUserBasic = (errorMessage = null, enabled, selection) => {
        let valid = true;
        const m: {errors?: string[]} = {};

        if (!enabled) {
            return {};
        }

        if (isNull(errorMessage) || !isString(errorMessage)) {
            errorMessage = 'Please make a valid selection.';
        }

        if (isNil(selection)) {
            valid = false;
        } else {
            const userId = selection.get('userId');
            valid = !isNil(userId);
        }

        if (!valid) {
            m.errors = [errorMessage];
        }
        return m;
    };

    static mergeCurrentValueIntoOptions = <T = unknown>(
        comparatorValueMapper: (item: T) => unknown,
        options: List<T>,
        currentValue: T,
    ): List<T> => {
        if (isNil(currentValue)) {
            return options;
        }

        if (options.some((option) => comparatorValueMapper(currentValue) === comparatorValueMapper(option))) {
            return options;
        }

        return options.push(currentValue);
    };

    static mergeCurrentValuesIntoOptions = <T = unknown>(
        comparatorValueMapper: (item: T) => unknown,
        options: List<T>,
        currentValues: List<T>,
    ): List<T> => {
        return currentValues.reduce(
            (accumulator, currentValue) =>
                FormUtils.mergeCurrentValueIntoOptions(comparatorValueMapper, accumulator, currentValue),
            options,
        );
    };

    static preventSubmit(event) {
        event.preventDefault();
    }

    // html input of type number allows exponent and positive/negative symbols
    static preventNonNumericInput(event) {
        if (['e', 'E', '+', '-'].includes(event.key)) {
            event.preventDefault();
        }
    }
}
